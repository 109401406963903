export const findFiveMatches = (matches, timePassed, teamName) => {
  const today = new Date();
  const dayCheck = (match) =>
    timePassed ? new Date(match.date) < today : new Date(match.date) >= today;
  const teamCheck = (match) =>
    match.home.fullName === teamName || match.away.fullName === teamName;

  const teamMatches = matches.filter(
    (match) => dayCheck(match) && teamCheck(match)
  );
  return timePassed ? teamMatches.slice(-5).reverse() : teamMatches.slice(0, 5);
};

export const findFiveClosestStandings = (standings, teamFullName) => {
  let fiveClosestStandings;

  const currentTeamsRank = standings.findIndex(
    (item) => item.fullName === teamFullName
  );

  if (currentTeamsRank < 3) {
    fiveClosestStandings = standings.slice(0, 5);
  } else if (currentTeamsRank < standings.length - 2) {
    fiveClosestStandings = standings.slice(
      currentTeamsRank - 2,
      currentTeamsRank + 3
    );
  } else {
    fiveClosestStandings = standings.slice(
      standings.length - 5,
      standings.length
    );
  }
  return fiveClosestStandings;
};
